// About.js
import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";

class Kata extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Kata and their meaning</h1>
                <p>Think of Kata as a link to the past masters, a book without text past down through generations. It is
                    an advanced form of training drill that teaches technique application. After practicing kata for
                    many hours the moves become muscle memory, then you can focus on what the kata means to achieve a
                    high level of understanding and performance. Kata training teaches focus, endurance, speed, balance,
                    power and strength. It also trains you to link combinations into a practical series of
                    techniques. </p>

                <h2>TOGKA Kata practiced:</h2>


                <h3>Kihon Dai Ichi (Basic Number 1)</h3>

                <p>Originally called fukyugata ichi, this kata was developed by Nagamine Shoshin, a member of the
                    Karate-Do Special Committee assembled in 1940. The goal of the commitee was to create a series of
                    Okinawan kata to teach both physical education and very basic Okinawan independent style martial
                    arts to school children. Their goal was not to create a standardized karate as the Japaneses had
                    been doing with kendo and Judo for the sake of popularization. This type of kata is not traditional
                    Goju Ryu kata; instead, it was developed as "promotional kata", independent of the sensei's
                    style.</p>

                <p>Using basic steps, hand technques and tai sabaki (body evasion), Kihon Dai Ichi is a simple
                    drill-styled kata which introduces kata to beginners. This kata was taught to Sensei Ravey by Sensei
                    Morio Higaonna in Yoyogi Dojo, Japan.</p>


                <h3>Kihon Dai Ni (Basic Number 2)</h3>

                <p>Almost identical to Kihon Dai Ichi, with the introduction of kicks. </p>


                <h3>Gekisai Dai Ichi 撃砕第一 (Introduction To Destroy Number 1)</h3>

                <p>Also a member of the Karate-Do Special Committee, Miyagi Sensei developed fukyugata ni in 1940, which
                    is part of current Goju Ryu syllabus under the name Gekisai Dai Ichi. This kata finishes with a
                    forward step that symbolises the moving forward of the country during the war in Japan at the time.
                    The kata involves 'attack' and 'smash' techniques to pulverise the opponent.</p>


                <h3>Gekisai Dai Ni 撃砕第二 (Introduction To Destroy Number 2)</h3>

                <p>This Kata was created at the same time as Gekasai Dai Ichi. Both of these Kata were created by Sensei
                    Chojun Miyagi as a means to strengthen and prepare the body for future rigorous training. It also
                    introduces open hand techniques and the Neko Ashi Dachi movements that are very important in many
                    advanced Goju Ryu kata. </p>


                <h3>Saifa 碎破 (To Destroy By Pounding/Pulverising)</h3>

                <p>To tear and destroy, also known as 'To Move Like The Wind'. Saifa is the first of the traditional
                    kata of Chinese origin taught in Goju Ryu. Kanryo Higaonna Sensei was taught this kata, along with
                    the other kata of Goju Ryu, while he studied in China from 1863-1881 under the direction of Ryu Ryu
                    Ko (Xie Zhong Xiang) and others. Saifa makes use of many escape techniques and body shifting
                    skills. </p>


                <h3>Seiunchin 制引戦 (To Control and Pull In Battle)</h3>

                <p>Derivative of a very old chinese kata, probably originally from the Hsing-I system. Seiunchin implies
                    the use of techniques to off balance, throw and grapple when grabbed around the collar and wrist
                    area. It is this understanding that imparts the original intentions of the kata of Naha-te before
                    the sport alignment of modern karate (ie - grappling instead of stand up punching). The subtleness
                    of ashi barai represents foot sweeps, parrys and traps.</p>


                <h3>Shisochin 四向戦 (Four Directional Battle)</h3>

                <p>Also called to 'Destroy In Four Directions', taught to Kanryo Higaonna by Ryu Ryu Ko. Shisochin was
                    one of Chojun Miyagi's favourite kata in his later years. It emphasises joint manipulation and
                    locking techniques. This kata is believed to have deeper philosophical meanings represented in
                    Chinese medicine for example wood, fire, metal, and water, with man representing earth.</p>


                <h3>Sanseru 三十六手 (36 Hands/Techniques)</h3>

                <p>This kata makes use of joint attacks and defence against kicking attacks. The name refers to a
                    systematic method of certain groupings of vital acupressure points. Feng Yiquan, who lived during
                    the Ming Dynasty (1522-67) developed this particular method of using variations of "36" forbidden
                    points to defeat his opponents. Sanseru is found in Crane, Tiger, and Dog Boxing styles.</p>

                <h4>Sepai 十八手 (18 Hands/Techniques)</h4>

                <p>The most apparent and meaningful suggestion in the naming of Sepai is again from the martial arts
                    development and the use of attacking pressure points.18 is one half of 36 suggesting that perhaps an
                    alternative set of attacks and defenses of preferred techniques and strategies from the original
                    Sanseru 36. This kata uses many movements that require co-ordination between hips and hands. Sepai
                    is found in Monk Boxing. </p>


                <h4>Kururunfa 久留頓破 (Holding on Long and Striking Suddenly)</h4>

                <p>Of Praying Mantis style, this kata uses many Neko Ashi movements and also refers to 'destroy with
                    ancient mantis techniques'. Taught to Kanryo Higaonna by Ryu Ryu Ko in China. Stance transitions are
                    quick and explosive while the hands techniques are employed using "muchimi" or a heavy, sticky
                    movement. </p>

                <h4>Sesan 十三手 (13 Hands/Techniques)</h4>

                <p>The basic form of this kata contains 8 defensive and 5 attacking techniques. Thirteen is also a
                    number representing good luck and prosperity in chinese numerology. Sesan is thought to be one of
                    the oldest of all Okinawan Goju Ryu kata. It symbolises the difference between Go (hard) and Ju
                    (soft). Sesan is practiced in the following styles of Chinese Boxing: Dragon, Lion and Monk
                    Fist.</p>


                <h4>Suparunpei 壱百零八手 (108 Hands/Techniques)</h4>

                <p>Combining the elements represented in the meanings of sanseru and sepai, the number "108" is
                    suggested to have origins in Buddhism and can represent the "108 sins of man". On the Chinese New
                    Year, temple bells are rung 108 times to "drive away the evils of man". This is the most advanced
                    Kata in Goju Ryu also known as Master Kata, containing the greatest number of techniques and
                    variations. Suparinpei is found in the styles of Chinese Boxing: Dragon, Tiger and Monk Fist. </p>

                <h4>Tensho 転掌 (Rotating Palms)</h4>

                <p>'Turning or Flowing Hands', Tensho is uniquely Okinawan. Miyagi Sensei developed Tensho to further
                    complete his Goju Ryu where Sanchin left off, including more intricate concepts of techniques. These
                    concepts come alive in kakie, which in advanced training, breathes life into the bunkai of Goju Ryu
                    kata.</p>

                <h4>Sanchin 三戦 (3 Battles - mind, body and spirit) </h4>

                <p>The fundamental kata of Goju Ryu using muscle contraction and ibuki style breathing. The kata
                    symbolises the conflict between mind, body and spirit. Sanchin develops discipline, determination,
                    focus, perseverance and other mental attributes.</p>

                <p>The original Sanchin was performed with open hands and less emphasis on muscle contraction and
                    "energetic" breathing. It was changed from open hands to closed fists as the martial meaning was no
                    longer emphasized. Later the kata was altered in pattern alone.</p>

                <hr/>

                <h2>Supplimentary Kata</h2>

                <h4>Nunchaku Kata Dai Ichi (Nunchaku kata number 1)</h4>

                <p>Developed by Sensei Ravey, based on floor pattern of Kihon Dai Ichi, this kata is an introduction to
                    striking and catching techniques while moving with nunchaku. </p>

                <h4>Nunchaku Kata Dai Ni (Nunchaku kata number 2)</h4>

                <p>Based on Nunchaku Kata Dai Ichi, this kata introduces kicking and nunchaku blocking techniques.</p>
            </Layout>);
    }
}

export default Kata;
